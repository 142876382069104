import React, { useState } from "react"
// import Head from "next/head"
import copy from "copy-to-clipboard"
import NotificationUtils from "../../utils/notification"
import TextArea from "../../components/elements/TextArea"
import Button from "../../components/elements/Button"
import { H1, H2 } from "../../components/elements/Headings"
import {
  UtilityButtonContainer,
  ContentContainer,
  PageTitleContainer,
} from "../../components/layout/Containers"
import SEO from "../../components/seo"
import Layout from "../../components/layout/Layout"
import { decode } from "../../services/base64"

const Base64Decode = () => {
  const [stringValue, setStringValue] = useState("")
  const [decodedValue, setDecodedValue] = useState("")

  const decodeValue = async () => {
    if (stringValue && stringValue.trim().length > 0) {
      let decodedString: string
      try {
        decodedString = decode(stringValue)
        setDecodedValue(decodedString)
      } catch (_) {
        NotificationUtils.error({
          title: "Error",
          description: "Error while deconding the string!",
        })
      }
    } else {
      reset()
    }
  }

  const reset = () => {
    setStringValue("")
    setDecodedValue(undefined)
  }

  const copyToClipboard = () => {
    copy(decodedValue)
    NotificationUtils.default({
      title: "Copied!",
      description: "Content copied to your clipboard",
    })
  }

  return (
    <>
      <SEO title="Base64 Decode" />
      <Layout>
        <PageTitleContainer>
          <H1>Base64 Decode</H1>
          <H2 className="text-xl font-light">
            Paste your string here and press the button!
          </H2>
        </PageTitleContainer>
        <ContentContainer>
          <TextArea
            onChange={setStringValue}
            value={stringValue}
            rows={10}
            placeholder={"The string you want to decode..."}
            className="self-start"
          />
          <UtilityButtonContainer>
            <Button click={decodeValue} primary>
              Decode
            </Button>
            <Button click={reset} accent>
              Reset
            </Button>
          </UtilityButtonContainer>
        </ContentContainer>

        {decodedValue && (
          <div className="px-4">
            <TextArea
              readOnly={true}
              rows={10}
              value={decodedValue}
              placeholder={"The decoded string..."}
              className="bg-gray-400"
            />
            <UtilityButtonContainer>
              <Button click={copyToClipboard} secondary>
                Copy to clipboard
              </Button>
            </UtilityButtonContainer>
          </div>
        )}
      </Layout>
    </>
  )
}

export default Base64Decode
